<script lang="ts" setup>
const FALLBACK_IMAGE_PATH = '/images/products/fallback-image.svg'

const props = withDefaults(
  defineProps<{ picture?: string; classes?: string; size?: ImageSize }>(),
  {
    picture: FALLBACK_IMAGE_PATH,
    classes: '',
    size: 'small',
  },
)

const hasError = ref(false)

const imageDimensions = ref({
  width: 0,
  height: 0,
})

const imgClass = computed(() => {
  if (hasError.value) {
    return `p-1 ${props.classes}`
  }
  if (imageDimensions.value.height > imageDimensions.value.width)
    return `h-full w-auto ${props.classes}`

  return `w-full h-auto ${props.classes}`
})

const imgSrc = computed(() => optimizedImage(getPictureUrl(props.picture), props.size))

onMounted(() => {
  const img = new Image()
  img.src = imgSrc.value || FALLBACK_IMAGE_PATH

  img.onload = () => {
    if (img.src.includes(FALLBACK_IMAGE_PATH)) {
      hasError.value = true
      imageDimensions.value.height = 20
      imageDimensions.value.width = 20
      return
    }

    hasError.value = false
    imageDimensions.value.height = img.height
    imageDimensions.value.width = img.width
  }
  img.onerror = () => {
    hasError.value = true
    img.src = FALLBACK_IMAGE_PATH
  }
})
</script>

<template>
  <img :class="imgClass" :src="imgSrc || FALLBACK_IMAGE_PATH" alt="" />
</template>
